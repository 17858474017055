import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';



@Component({
    selector: 'app-preparation-itsr3',
    templateUrl: './preparation-itsr3.component.html',
    styleUrls: ['./preparation-itsr3.component.css'],
    standalone: false
})
export class PreparationITSR3Component implements OnInit {


  constructor(
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    const redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl');
    if (!redirectUrl) {
      throw new Error(`redirectUrl not set`);
    }
    if (typeof window !== 'undefined') {
      const redirectTo = new URL(redirectUrl, window.location.href);
      console.log(redirectUrl);
      console.log(window.location.href);
      const targetUrl = 'itsr3:' + redirectTo;
      //don't call navigateAway, because it forwards call to desktop app
      window.location.href = targetUrl;
    }
  }

}

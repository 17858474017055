import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MyOidcAuthService } from './my-oidc-auth.service';
import { PreparationExamService } from './preparation-exam.service';

@Injectable()
export class NeedAuthGuard  {

    constructor(
        readonly svc: PreparationExamService,
        readonly authSvc: MyOidcAuthService,
        private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const redirectUrl = this.router.url;
        console.log(`call tryLoginOidc`);
        this.svc.tryLoginOidc();
        if (this.svc.isLoggedIn) {
            return true;
        }

        this.svc.navigate(['/login'], {
            redirectUrl
        });

        return false;
    }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { MyOidcAuthService } from '../my-oidc-auth.service';
import { LoginTokenService, PreparationExamService } from '../preparation-exam.service';
import * as Q from './check-proctor.query.g';

@Component({
    selector: 'app-login-oidc-proctor',
    templateUrl: './login-oidc-proctor.component.html',
    styleUrls: ['./login-oidc-proctor.component.css'],
    standalone: false
})
export class LoginOidcProctorComponent implements OnInit {
    public token: string | undefined;
    public isProctor: boolean | undefined;
    public isLoading: boolean = true;
    public displayToken = false;
    constructor(
        private readonly qSvc: Q.ICheck_ProctorGQL,
        private readonly router: Router,
        private readonly svc: PreparationExamService,
        private readonly tokenSvc: LoginTokenService,
        private readonly oidc: MyOidcAuthService,
    ) { }

    async ngOnInit() {
        this.svc.tryLoginOidc();
        this.token = this.tokenSvc.token;
        try {
            const r = await firstValueFrom(this.qSvc.fetch({}));
            if (r.errors) {
                console.error(r.errors);
            }
            this.isProctor = r.data && r.data.whoAmI.isProctor || false;
            if (this.isProctor) {
                await this.nav();
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }
    public async nav() {
        if (this.isProctor) {
            console.log('navigating to cert-dashboard');
            const ok = await this.router.navigate(['cert-dashboard'], {});
            if (!ok) {
                console.error('Unable to navigate to cert-dashboard');
            }
        }
    }
    public login() {
        this.oidc.logout();
        this.token = this.tokenSvc.token;
        this.oidc.login();
    }

}

import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import  { DxDataGridComponent } from 'devextreme-angular';
//import type DevExpress from 'devextreme/bundles/dx.all';
import type dxDataGrid from 'devextreme/ui/data_grid';
import type DxDataGridTypes from 'devextreme/ui/data_grid_types';
import type dxButton from 'devextreme/ui/button_types';
import type store from 'devextreme/data/store';
import { exportDataGrid } from 'devextreme/common/export/excel'

import CustomStore from 'devextreme/data/custom_store';
import { formatDate } from 'devextreme/localization';
import { firstValueFrom } from 'rxjs';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
// @ts-ignore
import XlsxPopulate from 'xlsx-populate';
import { AppConfigService } from '../app-config.service';
import { AppInitService } from '../app-init.service';
import { AS, assertNever, button_click, button_visible, dxAlert, dxConfirm, toolbar_preparing, _ } from '../dx_helper';
import { insertSpaces } from '../helper';
import { ITEM_STATUS } from '../interfaces';
import * as Types from '../its-organizer-api.types.g';
import { LoginTokenService, PreparationExamService } from '../preparation-exam.service';
import * as CLOSE from './closeTanGroup.mutation.g';
import * as Q from './data.query.g';
import * as LAUNCH from './launchUntracked.mutation.g';
import * as NEWTOKEN from './newTanGroupToken.mutation.g';
import * as REFRESH from './refreshTanGroup.mutation.g';
import * as SETNAME from './setTanGroupNames.mutation.g';
import { FocusedRowChangedEvent } from 'devextreme/ui/data_grid_types';

let lastEvent: dxButton.ClickEvent['event'];

let running: Promise<unknown> | undefined;

function normalizeTan(tan: string) {
    if (!tan) {
        return '';
    }
    return tan.replace(/\s+/g, '').toLowerCase();
}

interface DownloadableFile {
    id: string;
    text: string;
    type: 'pdf' | 'zip';
    filename: string;
}

function chooseFiles(): Promise<File[]> {
    return new Promise((resolve, reject) => {
        let input = document.createElement('input');
        input.style.cssText = 'position:absolute;left:0;top:-999em;';
        input.type = 'file';
        input.multiple = true;

        document.body.appendChild(input);

        const done = () => {
            if (input) {
                document.body.removeChild(input);
                resolve([].slice.call(input.files || []));
                //input = null;
            }
        };

        input.addEventListener('change', done);
        addEventListener('focus', () => setTimeout(done, 1000));

        input.click();
    });
}



// type ITEM_STATUS = '+' | '-' | 'o';
type TAN = Q.ITangroup_DataQuery['tanGroup']['tans'][0] & {
    nr: number,
    tanUsed: boolean,
    itemstatus: { [key: string]: ITEM_STATUS }
};
type OBJECTIVE = NonNullable<Q.ITangroup_DataQuery['tanGroup']['tans'][0]['objectives']>[0];

const MAX_TOP_ITEMS = 100;
@Component({
    selector: 'app-preparation-tangroup',
    templateUrl: './preparation-tangroup.component.html',
    styleUrls: ['./preparation-tangroup.component.css'],
    standalone: false
})
export class PreparationTangroupComponent implements OnInit {
    @ViewChildren(DxDataGridComponent) dataGrids?: QueryList<DxDataGridComponent>;
    @ViewChild('dataGridProgress', { static: true }) dataGridProgress?: DxDataGridComponent;
    @ViewChild('dataGridOverview', { static: true }) dataGridOverview?: DxDataGridComponent;

    public files: CustomStore;
    public status?: Types.TanGroupStatus;
    public supportSource?: string;
    public showSupportPage?: boolean;

    private hideObjectiveId?: boolean;

    constructor(
        private readonly qSvc: Q.ITangroup_DataGQL,
        private readonly setNameSvc: SETNAME.ISetTanGroupNamesGQL,
        private readonly newTokenSvc: NEWTOKEN.INewTanGroupTokenGQL,
        private readonly closeSvc: CLOSE.ICloseTanGroupGQL,
        private readonly launchSvc: LAUNCH.ILaunchUntrackedGQL,
        private readonly refreshSvc: REFRESH.IRefreshTanGroupGQL,
        private route: ActivatedRoute,
        private router: Router,
        private readonly tokenSvc: LoginTokenService,
        private initSvc: AppInitService,
        private service: PreparationExamService,
        private translate: TranslateService,
        private config: AppConfigService,
    ) {


        this.files = new CustomStore({
            key: 'id',
            load: async () => {
                const files: DownloadableFile[] = [
                    {
                        id: '1',
                        text: 'Gesamtbericht',
                        type: 'pdf',
                        filename: this.tanGroupName + '.pdf',
                    },
                    {
                        id: '2',
                        text: 'Alle Berichte als ZIP',
                        type: 'zip',
                        filename: this.tanGroupName + '.zip',
                    }
                ];
                return files;
            }
        });
        this.tanDS = new CustomStore({
            key: 'id',
            load: async () => {
                const id = this.id;
                if (!id) { throw new Error('id not set') };
                const password = this.service.getTanGroupPassword(id);
                const q = await firstValueFrom(this.qSvc.fetch({
                    id,
                    password,
                }));

                this.status = q.data.tanGroup.status;
                let hasOpen = false;
                let hasIncorrect = false;
                let hasCorrect = false;
                let hasCompleted = false;
                let hasCurrent = false;
                let hasPostponed = false;
                let hasPartiallyCorrect = false;

                this.validUntil = formatDate(new Date(q.data.tanGroup.validTo), 'longdate');
                this.testcenterId = q.data.tanGroup.testcenter.testcenterId;
                this.tanGroupName = q.data.tanGroup.name;
                let topItemCount = 1;
                this.tans = q.data.tanGroup.tans.sort((a, b) => a.id.localeCompare(b.id)).map((x, xIdx) => {
                    const p = x.progress || '';
                    if (p.length > topItemCount) {
                        topItemCount = p.length;
                    }
                    const itemstatus: { [key: string]: ITEM_STATUS } = {};
                    hasOpen = hasOpen || p.indexOf(ITEM_STATUS.open) >= 0;
                    hasIncorrect = hasIncorrect || p.indexOf(ITEM_STATUS.incorrect) >= 0;
                    hasCorrect = hasCorrect || p.indexOf(ITEM_STATUS.correct) >= 0;
                    hasPartiallyCorrect = hasPartiallyCorrect || p.indexOf(ITEM_STATUS.partiallyCorrect) >= 0;
                    hasCurrent = hasCurrent || p.indexOf(ITEM_STATUS.current) >= 0;
                    hasCompleted = hasCompleted || p.indexOf(ITEM_STATUS.completed) >= 0;
                    hasPostponed = hasPostponed || p.indexOf(ITEM_STATUS.postponed) >= 0;

                    p.split('').map((val, idx) => itemstatus[`${idx + 1}`] = <ITEM_STATUS>val);
                    return Object.assign(x, {
                        itemstatus,
                        nr: xIdx + 1,
                        tanUsed: x.status !== Types.TanStatus.Open,
                    });
                });

                this.hasOpen = hasOpen;
                this.hasIncorrect = hasIncorrect;
                this.hasCorrect = hasCorrect;
                this.hasPartiallyCorrect = hasPartiallyCorrect;
                this.hasCompleted = hasCompleted;
                this.hasCurrent = hasCurrent;
                this.hasPostponed = hasPostponed;
                this.topitem_count = topItemCount;
                return this.tans;

            },
            update: async (key, values) => {
                if (!this.id) { throw new Error('id not set') };
                if (values.name) {
                    const r = await firstValueFrom(this.setNameSvc.mutate({
                        tanGroupId: this.id,
                        password: this.service.getTanGroupPassword(this.id),
                        tans: [key],
                        names: [values.name]
                    }));
                    if (r.errors) {
                        throw new Error(r.errors[0].message);
                    }
                }
                return values;
            }
        },

        );

    }

    private roToken = '';
    public hasCorrect = true;
    public hasIncorrect = true;
    public hasPartiallyCorrect = true;
    public hasOpen = true;
    public hasCompleted = true;
    public hasPostponed = true;
    public hasCurrent = true;
    public id?: string;
    public testcenterId?: string;
    public tanGroupName?: string;
    public validUntil?: string;
    public tans?: TAN[];
    private topitem_count = 1;

    public tanDS: store.Store;
    public focusedTan?: TAN;


    onOverviewToolbarPreparing = toolbar_preparing(e => {
        if (!e.toolbarOptions.items) {
            e.toolbarOptions.items = [];
        }
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                widget: 'dxButton',
                options: AS<dxButton.Properties>({
                    text: this.translate.instant(_('preparation.tangroup.button.export')),
                    onClick: this.export.bind(this),
                })
            },
            {
                location: 'before',
                widget: 'dxButton',
                options: AS<dxButton.Properties>({
                    text: this.translate.instant(_('preparation.tangroup.button.import')),
                    onClick: button_click(async eClick => {
                        if (lastEvent === eClick.event) {
                            console.log('multiple calls prohibited');
                            return;
                        }
                        if (running) {
                            console.log('still running');
                            return;
                        }
                        console.dir(eClick.event);
                        lastEvent = eClick.event;
                        try {
                            running = this.import();
                            await running;
                        } finally {
                            running = undefined;
                        }
                    })
                })
            },
            {
                location: 'before',
                widget: 'dxButton',
                options: AS<dxButton.Properties>({
                    text: this.translate.instant(_('preparation.tangroup.button.printoverview')),
                    onClick: this.overview.bind(this),
                })
            }
        );
    });
    onProgressToolbarPreparing = toolbar_preparing(e => {
        if (!e.toolbarOptions.items) {
            e.toolbarOptions.items = [];
        }
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                widget: 'dxButton',
                options: AS<dxButton.Properties>({
                    text: this.translate.instant(_('preparation.tangroup.button.launch')),
                    onClick: this.launchUntracked.bind(this),
                })
            });
    });
    onResultsToolbarPreparing = toolbar_preparing(e => {
        if (!e.toolbarOptions.items) {
            e.toolbarOptions.items = [];
        }
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                widget: 'dxButton',
                options: AS<dxButton.Properties>({
                    text: this.translate.instant(_('preparation.tangroup.button.finish')),
                    onClick: this.closeTanGroup.bind(this),
                })
            });
    });

    public isCandidatePdfVisible = button_visible<TAN>((e: TAN) => {
        return !!e.score;
    });

    tanCustomText = (cellInfo: { value: string, valueText: string }) => {
        return insertSpaces(cellInfo.value);
    }


    hasFile = (file: DownloadableFile) => {
        return this.status === Types.TanGroupStatus.Done;
    }


    filesOnFocusedRowChanged = async (e: FocusedRowChangedEvent) => {
        this.focusedTan = e.row && e.row.data;
    }

    displayObjective(obj: OBJECTIVE) {
        if (!obj) {
            return '';
        }
        const id = this.hideObjectiveId ? '' : obj.key;
        const translated = this.translate.instant(_('preparation.tangroup.detailview.objective'), {
            id,
            description: obj.description,
            gained: obj.score.gained,
            max: obj.score.max,
            percent: Math.round(obj.score.gained * 100 / obj.score.max)
        });
        return translated.trim();
    }
    openFile = async (file: DownloadableFile) => {
        if (file.type === 'pdf') {
            const tanGroupId = encodeURIComponent(this.id!);
            const access_token = encodeURIComponent(this.tokenSvc.token!);
            const password = encodeURIComponent(this.service.getTanGroupPassword(this.id!));
            const url = this.config.getApiUrl() + `tangroup/${tanGroupId}/report.pdf?access_token=${access_token}&password=${password}`;
            console.log(url);
            window.open(url);
        } else {
            const tanGroupId = encodeURIComponent(this.id!);
            const access_token = encodeURIComponent(this.tokenSvc.token!);
            const password = encodeURIComponent(this.service.getTanGroupPassword(this.id!));
            const url = this.config.getApiUrl() + `tangroup/${tanGroupId}/reports.zip?access_token=${access_token}&password=${password}`;
            console.log(url);
            window.open(url);
        }
    }
    openCandidatePdf = async (tan: TAN) => {
        const tanId = encodeURIComponent(tan.id);
        const access_token = encodeURIComponent(this.tokenSvc.token!);
        const password = encodeURIComponent(this.service.getTanGroupPassword(this.id!));
        const url = this.config.getApiUrl() + `tan/${tanId}/report.pdf?access_token=${access_token}&password=${password}`;
        console.log(url);
        window.open(url);
    }

    async nyi() {
        await dxAlert('Not yet implemented', 'NYI');
    }

    goBack() {
        return this.service.navigate(['preparation-dashboard'], {});
    }



    onCellPrepared = (e: any) => {
        if (e.rowType === 'data' && e.column.dataField === 'tan') {
            e.cellElement.style.fontFamily = 'Consolas, sans-serif';
        }
    }

    async getReadOnlyToken() {
        if (this.roToken) {
            return this.roToken;
        }
        const r = await firstValueFrom(this.newTokenSvc.mutate({
            id: this.id!,
            readonly: true,
            password: this.service.getTanGroupPassword(this.id!),
        }));
        const token = r && r.data && r.data.newTanGroupToken;
        if (!token) {
            throw new Error('token not received');
        }
        this.roToken = token;
        return this.roToken;
    }

    async openExternal(relUrl: string) {
        const wnd = window.open('');
        if (!wnd) {
            await dxAlert('unable to open window', 'error');
            return;
        }
        const token = await this.getReadOnlyToken();
        const url = new URL(relUrl, window.location.href);
        url.searchParams.append('access_token', token);

        //don't call navigateAway because wnd is a popup window
        wnd.location.href = url.href;

    }
    overview() {
        return this.openExternal(`${encodeURIComponent(this.id!)}/overview`);
    }

    async import() {
        try {
            const files = await chooseFiles();
            console.dir(files);
            for (const file of files) {
                const wb = await XlsxPopulate.fromDataAsync(file);
                const values = wb.sheet(0)!.usedRange()!.value();
                const tanColName: string = this.translate.instant(_('preparation.tangroup.grid.tan'));
                const nameColName: string = this.translate.instant(_('preparation.tangroup.grid.for'));
                const tanIdx: number = values[0].findIndex((x: string | undefined) => x === tanColName);
                const nameIdx: number = values[0].findIndex((x: string | undefined) => x === nameColName);
                if (tanIdx === -1 || nameIdx === -1) {
                    await dxAlert(
                        this.translate.instant(_('preparation.tangroup.error.invalidxlsx')),
                        this.translate.instant(_('preparation.tangroup.error.invalidxlsx_title')));
                    continue;
                }
                const names: string[] = values.slice(1).map((x: Array<string | undefined>) => {
                    const name = x[nameIdx];
                    if (typeof name === 'string') {
                        return name;
                    } else {
                        return '';
                    }
                });
                const tans: string[] = values.slice(1).map((x: Array<string | undefined>) => {
                    const tan = x[tanIdx];
                    if (typeof tan === 'string') {
                        return normalizeTan(tan);
                    } else {
                        return '';
                    }
                });
                const matchingTans = tans.filter(x => this.tans!.find(y => y.tan === x));
                if (!matchingTans.length) {
                    await dxAlert(
                        this.translate.instant(_('preparation.tangroup.error.invalidxlsx')),
                        this.translate.instant(_('preparation.tangroup.error.invalidxlsx_title')));
                    continue;
                }
                const r = await firstValueFrom(this.setNameSvc.mutate(
                    {
                        tanGroupId: this.id!,
                        password: this.service.getTanGroupPassword(this.id!),
                        names,
                        tans
                    }));

            }
        } catch (e) {
            await dxAlert(
                this.translate.instant(_('preparation.tangroup.error.invalidxlsx')),
                this.translate.instant(_('preparation.tangroup.error.invalidxlsx_title')));
            console.dir(e);
        }
        await this.refresh(false);
    }

    async export() {
        if (!this.dataGridOverview) {
            return;
        }
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const component: dxDataGrid = this.dataGridOverview.instance;
        await exportDataGrid({
            worksheet,
            component,
            autoFilterEnabled: true,
        });
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    }


    async closeTanGroup() {
        if (!await dxConfirm(
            this.translate.instant(_('preparation.tangroup.confirm.close')),
            this.translate.instant(_('preparation.tangroup.confirm.close_title')))) {
            return;
        }
        const r = await firstValueFrom(this.closeSvc.mutate({
            tanGroupId: this.id!,
            password: this.service.getTanGroupPassword(this.id!)
        }));
        const ok = r && r.data && r.data.closeTanGroup;
        if (!ok) {
            await dxAlert(
                this.translate.instant(_('preparation.tangroup.error.close')),
                this.translate.instant(_('preparation.tangroup.error.close_title')));
            return;
        }
        await this.refresh(false);
    }
    async launchUntracked() {
        const wnd = window.open('', '', 'fullscreen=yes');
        const r = await firstValueFrom(this.launchSvc.mutate({
            tanGroupId: this.id!,
            userAgentString: window.navigator.userAgent,
        }));
        const url = r.data && r.data.launchTanGroupModuleUntracked;
        if (!url) {
            wnd?.close();
            await dxAlert(
                this.translate.instant(_('preparation.tangroup.error.launch')),
                this.translate.instant(_('preparation.tangroup.error.launch_title')));
            return;
        }
        if (url && wnd) {
            //don't call navigateAway, because this is not 'our' window but a popup window.
            wnd.location.href = url;
        }
    }
    async refresh(manualClick: boolean) {
        const id = this.id;

        const refresh = await firstValueFrom(this.refreshSvc.mutate({
            tanGroupId: id!,
        }));

        const password = this.service.getTanGroupPassword(id!);
        const q = await firstValueFrom(this.qSvc.fetch({
            id: id!,
            password
        }));

        this.validUntil = formatDate(new Date(q.data.tanGroup.validTo), 'longdate');
        this.testcenterId = q.data.tanGroup.testcenter.testcenterId;
        this.tanGroupName = q.data.tanGroup.name;
        let topItemCount = 1;
        this.tans = q.data.tanGroup.tans.sort((a, b) => a.id.localeCompare(b.id)).map((x, xIdx) => {
            const p = x.progress || '';
            if (p.length > topItemCount) {
                topItemCount = p.length;
            }
            const itemstatus: { [key: string]: ITEM_STATUS } = {};
            p.split('').map((val, idx) => itemstatus[`${idx + 1}`] = <ITEM_STATUS>val);
            return Object.assign(x, {
                itemstatus,
                nr: xIdx + 1,
                tanUsed: x.status !== Types.TanStatus.Open,
            });
        });
        this.topitem_count = topItemCount;


        if (this.dataGridProgress) {
            this.updateProgressVisibility(this.dataGridProgress.instance);
        }
        if (this.dataGrids) {
            await Promise.all(this.dataGrids.map(x => x.instance.refresh()));
        }
    }


    getImage = (data: { text: string, displayValue: string }) => {
        const status = <ITEM_STATUS>data.displayValue;
        if (!status) {
            return undefined;
        }
        switch (status) {
            case ITEM_STATUS.completed:
                return '/assets/images/item_completed.svg';
            case ITEM_STATUS.correct:
                return '/assets/images/item_correct.svg';
            case ITEM_STATUS.incorrect:
                return '/assets/images/item_incorrect.svg';
            case ITEM_STATUS.partiallyCorrect:
                return '/assets/images/item_partiallycorrect.svg';
            case ITEM_STATUS.postponed:
                return '/assets/images/item_postponed.svg';
            case ITEM_STATUS.current:
                return '/assets/images/item_current.svg';
            case ITEM_STATUS.open:
                return '/assets/images/item_open.svg';
            default:
                assertNever(status);
                return undefined;
        }
    }
    customizeProgressColumns = (columns: Array<DxDataGridTypes.Column>) => {
        const cols: DxDataGridTypes.Column[] = [];
        const itemCount = MAX_TOP_ITEMS;
        while (cols.length < itemCount) {
            const idx = cols.length + 1;
            cols.push({

                dataField: `itemstatus.${idx}`,
                caption: `${idx}`,
                allowSorting: false,
                allowResizing: false,
                allowReordering: false,
                alignment: 'center',
                visible: idx <= this.topitem_count,
                cellTemplate: 'itemstatus',
                width: 40,
            });
        }
        columns.push(...cols);
        // columns.push({});
    }

    updateProgressVisibility(component: dxDataGrid) {
        for (let i = 1; i <= MAX_TOP_ITEMS; ++i) {
            component.columnOption(`itemstatus.${i}`, 'visible', i <= this.topitem_count);
        }
    }
    calculateIncorrect = (options: { summaryProcess: 'start' | 'calculate' | 'finalize', name: string, totalValue: any, value: any }) => {
        if (options.name === 'itemstatus') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if (options.value === '-') {
                    options.totalValue++;
                }
            } else if (options.summaryProcess === 'finalize') {
            }
        }
    }
    onInitDgProgress = (o: { component?: dxDataGrid }) => {
        if (!o.component) {
            return;
        }
        let s = o.component.option('summary');
        if (!s) {
            s = {};
        }
        console.dir(s);
        if (!s.totalItems) {
            s.totalItems = [];
        }

        for (let i = 1; i <= MAX_TOP_ITEMS; ++i) {
            s.totalItems.push({
                name: 'itemstatus',
                summaryType: 'custom',
                column: `itemstatus.${i}`,

            });
        }
        o.component.option('summary', s);
        this.updateProgressVisibility(o.component);
    }

    hasResult(tan: TAN | undefined | null) {
        if (!tan) {
            return false;
        }
        return tan.status === Types.TanStatus.Completed;
    }

    async ngOnInit() {
        const id = this.route.snapshot.paramMap.get('id');
        this.id = id!;

        const config = await this.initSvc.getSettings();
        this.supportSource = config.supportIFrameUrl ?? undefined;
        this.hideObjectiveId = config.hideObjectiveId;
        this.showSupportPage = !!this.supportSource;

        await this.refresh(false);
    }

}

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { AppInitService } from '../app-init.service';

@Component({
    selector: 'app-refresh-button',
    templateUrl: './refresh-button.component.html',
    styleUrls: ['./refresh-button.component.css'],
    standalone: false
})
export class RefreshButtonComponent implements OnInit, OnDestroy {

  public buttonDisabled = false;
  public autoRefreshInterval = 0;
  private blockRefresh = 0;

  public nextRefresh?: number;
  public currentTick = 0;
  private timer?: Subscription;

  public absRefreshTick?: number;
  public ticksToNextRefesh?: number;

  public absBlockUntilTick?: number;
  public ticksToUnblock?: number;

  constructor(
    private initSvc: AppInitService,
  ) { }

  @Output()
  readonly refresh = new EventEmitter<boolean>();

  private updateDisabled() {
    let ticksToUnblock = this.absBlockUntilTick && (this.absBlockUntilTick - this.currentTick);
    if ((ticksToUnblock ?? -1) < 0) {
      ticksToUnblock = undefined;
    }
    this.ticksToUnblock = ticksToUnblock;
    this.buttonDisabled = (ticksToUnblock ?? 0) > 0;
  }

  public onClick() {
    this.doClick(true);
  }

  public doClick(manualClick: boolean) {
    this.absBlockUntilTick = this.currentTick + this.blockRefresh;
    this.updateDisabled();
    this.refresh.emit(manualClick);

    this.scheduleNext();
  }

  ngOnDestroy() {
    if (this.timer) {
      this.timer.unsubscribe();
    }
  }

  private scheduleNext() {
    if (!this.autoRefreshInterval) {
      this.absRefreshTick = undefined;
      return;
    }
    this.absRefreshTick = this.currentTick + this.autoRefreshInterval;
  }

  async ngOnInit() {
    this.timer = interval(1000).subscribe({
      next: x => {
        this.currentTick = x;
        let ticksToNextRefesh = this.absRefreshTick && (this.absRefreshTick - this.currentTick);
        if ((ticksToNextRefesh ?? 0) < 0) {
          ticksToNextRefesh = undefined;
        }
        this.ticksToNextRefesh = ticksToNextRefesh;
        if (ticksToNextRefesh === 0) {
          this.doClick(false);
        }
        this.updateDisabled();
      }
    });
    const settings = await this.initSvc.getSettings();
    this.autoRefreshInterval = settings.autoRefreshInterval;
    this.blockRefresh = settings.blockRefresh;
    this.absBlockUntilTick = this.currentTick + this.blockRefresh;
    this.updateDisabled();
    console.log(`autoRefreshInterval=${this.autoRefreshInterval}, blockRefresh=${this.blockRefresh}`);
    this.scheduleNext();
  }

}

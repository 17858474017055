import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DxDataGridComponent } from 'devextreme-angular';
import type DevExpress from 'devextreme/bundles/dx.all';
import moment from 'moment';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { AppInitService } from '../app-init.service';
import { AS, button_visible, dxConfirm, toolbar_preparing, _ } from '../dx_helper';
import * as Types from '../its-organizer-api.types.g';
import { addMetaDataFields } from '../login/addMetaDataFields';
import { PreparationExamService } from '../preparation-exam.service';
import * as CREATETANGROUP from './createTanGroup.mutation.g';
import * as Q from './data.query.g';
import * as DELETE from './deleteTanGroup.mutation.g';
import * as GETTC from './get_testcenters.query.g';
import * as INIT from './init.query.g';

type TanGroup = Q.IGet_Testcenter_By_Id2Query['testcenter']['tanGroups'][0] & {
    password: string,
    isDone: boolean
};
type Module = Q.IGet_Testcenter_By_Id2Query['testcenter']['modules'][0];
type TC = GETTC.IGet_TestcentersQuery['testcenters'][0] & {
    display: string
};

@Component({
    selector: 'app-preparation-dashboard',
    templateUrl: './preparation-dashboard.component.html',
    styleUrls: ['./preparation-dashboard.component.css'],
    standalone: false
})
export class PreparationDashboardComponent implements OnInit, OnDestroy {
    @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent | undefined;

    private readonly subscriptions = new Array<Subscription>();
    private init: INIT.ITangroup_InitQuery | undefined;

    public newExamFormItems: DevExpress.ui.dxForm.SimpleItem[] = [];

    constructor(
        private readonly qSvc: Q.IGet_Testcenter_By_Id2GQL,
        private readonly createTanGroupSvc: CREATETANGROUP.ICreateTanGroupGQL,
        private readonly getTcSvc: GETTC.IGet_TestcentersGQL,
        private readonly initGqlSvc: INIT.ITangroup_InitGQL,
        private readonly deleteSvc: DELETE.IDeleteTanGroupGQL,
        private readonly svc: PreparationExamService,
        private readonly initSvc: AppInitService,
        config: AppConfigService,
        private readonly translate: TranslateService,
        private readonly route: ActivatedRoute) {

        const today = moment().startOf('day');
        this.minValidTo = today.toDate();
        this.maxValidTo = today.toDate();

        this.headerFilterDataDone = {
            load: function () {
                return [
                    {
                        text: translate.instant('preparation.dashboard.filter.done_true'),
                        value: true
                    },
                    {
                        text: translate.instant('preparation.dashboard.filter.done_false'),
                        value: false
                    },
                    /*{
                      text: 'Earlier',
                      value: ['OrderDate', '<', startOfWeek]
                    }*/
                ];
            }
        };
    }

    public testcenterId: string | undefined;
    public headerFilterDataDone: any = {};
    public tan_allowance = 0;
    public tan_used = 0;
    public testcenters: TC[] = [];

    public tanGroups: TanGroup[] | null = null;
    public modules: Module[] | null = null;

    public minValidTo: Date;
    public maxValidTo: Date;
    public maxTans = 0;

    public newExamFormData: {
        module: string;
        password: string;
        tans: number;
        name: string;
        valid_to: Date | undefined;
        metadata: { [key: string]: string }
    } = {
            module: '',
            password: '',
            tans: 15,
            name: '',
            valid_to: undefined,
            metadata: {}
        };

    public newExamVisible = false;

    isOpenExamVisible = button_visible<TanGroup>(e => {
        return e.status === 'open';
    });

    isInfoExamVisible = button_visible<TanGroup>(e => {
        return e.status === 'done';
    });

    onToolbarPreparing = toolbar_preparing(e => {
        if (!e.toolbarOptions.items) {
            e.toolbarOptions.items = [];
        }
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                widget: 'dxButton',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    text: this.translate.instant(_('preparation.dashboard.button.new_tangroup')),
                    onClick: this.newExam.bind(this),
                })
            },
            {
                location: 'before',
                widget: 'dxButton',
                options: AS<DevExpress.ui.dxButton.Properties>({
                    text: this.translate.instant(_('preparation.dashboard.button.delete')),
                    onClick: this.deleteSelected.bind(this),
                })
            });
    });

    async logout() {
        await this.svc.logout();
    }

    async deleteSelected() {
        if (!this.grid) {
            return;
        }
        const selected: string[] = this.grid.selectedRowKeys;
        if (!selected.length) {
            return;
        }
        if (!await dxConfirm(
            this.translate.instant(_('preparation.dashboard.confirm.delete'), {
                count: selected.length
            }),
            this.translate.instant(_('preparation.dashboard.confirm.delete_title')))) {
            return;
        }
        const count = { deleted: 0, error: 0 };
        for (const tanGroupId of selected) {
            count.deleted++;
            try {
                await firstValueFrom(this.deleteSvc.mutate({
                    password: this.svc.getTanGroupPassword(tanGroupId),
                    tanGroupId
                }));
            } catch (e) {
                console.log(e);
                count.error++;
            }
        }
        if (count.error) {
            await this.svc.alert(_('preparation.dashboard.error.delete'), _('preparation.dashboard.error.delete_title'), {
                count: count.error
            });
        }
        await this.refresh();
    }

    get allowanceInfo() {
        const tc = this.testcenters.find(x => x.testcenterId === this.testcenterId);
        if (!tc) {
            return '';
        }
        if (this.tan_allowance < 0) {
            return '';
        }
        return this.translate.instant('preparation.dashboard.dialog.new.allowance', {
            remaining: this.tan_allowance - this.tan_used,
            used: this.tan_used,
            max: this.tan_allowance
        });
    }
    async newExam() {
        if (!this.modules || !this.modules.length) {
            return;
        }
        if (this.tan_allowance >= 0) {
            const remaining = this.tan_allowance - this.tan_used;
            if (remaining <= 0) {
                this.maxTans = 0;
                await this.svc.alert(_('preparation.dashboard.error.allowance'), _('preparation.dashboard.error.allowance_title'), {
                    remaining: this.tan_allowance - this.tan_used,
                    used: this.tan_used,
                    max: this.tan_allowance
                });
                return;
            }
            this.maxTans = remaining;
        }

        const config = await this.initSvc.getSettings();
        const today = moment().startOf('day');
        this.newExamFormData.name = '';
        this.newExamFormData.module = this.modules[0].id;
        this.newExamFormData.tans = config.newGroupTanCount;
        this.newExamFormData.password = '';
        this.newExamFormData.valid_to = today.clone().add('2', 'days').toDate();
        this.maxValidTo = today.clone().add(config.tanMaxDays - 1, 'days').toDate();
        this.setupFormItems();
        this.newExamVisible = true;
    }
    async generateTanGroup(e: Event) {
        console.log(`generateTanGroup()`);
        e.preventDefault();
        const metadata = new Array<Types.IMetaDataValueInput>();
        const formMeta = this.newExamFormData.metadata || {};
        if (this.init && this.init.tanGroupMetaData.length) {
            for (const { key } of this.init.tanGroupMetaData) {
                console.dir(this.newExamFormData);
                const value = formMeta[key] || '';
                metadata.push({
                    key,
                    value
                });
            }
        }
        const result = await firstValueFrom(this.createTanGroupSvc.mutate({
            password: this.newExamFormData.password,
            maxTans: this.newExamFormData.tans,
            testcenter: this.testcenterId ?? '',
            module: this.newExamFormData.module,
            name: this.newExamFormData.name,
            validTo: moment(this.newExamFormData.valid_to).endOf('day').toISOString(),
            metadata
        }
        ));
        const newTanGroupId = result && result.data && result.data.newTanGroup && result.data.newTanGroup.id;
        if (!newTanGroupId) {
            await this.svc.alert(
                _('preparation.dashboard.error.unable_to_create_new_exam'),
                _('preparation.dashboard.error.unable_to_create_new_exam_title'));
            return;
        }
        this.svc.setTanGroupPassword(newTanGroupId, this.newExamFormData.password);
        this.newExamVisible = false;
        await this.refresh();
    }

    onValueChangedTC = async (e: { previousValue?: string, value?: string }) => {
        const previousValue = e.previousValue;
        const newValue = e.value;
        if (newValue) {
            this.svc.navigate(['/preparation-dashboard', newValue], {});
        }
        // Event handling commands go here
    }
    openExam = async (e: { event: Event, row: { data: TanGroup } }) => {
        e.event.preventDefault();
        const data = e.row.data;
        if (!data.password && data.hasPassword) {
            await this.svc.alert(_('preparation.dashboard.error.password_needed'),
                _('preparation.dashboard.error.password_needed_title'));
            return;
        }
        if (!await this.svc.validateTanGroupPassword(data.id, data.password || '')) {
            await this.svc.alert(_('preparation.dashboard.error.invalid_password'),
                _('preparation.dashboard.error.invalid_password_title'));
            return;
        }
        this.svc.navigate(['/preparation-tangroup', data.id], {
        });
    }

    customizeColumns(columns: Array<DevExpress.ui.dxDataGrid.Column>) {
        console.dir(columns);

    }


    async refresh() {
        const tcsQuery = await firstValueFrom(this.getTcSvc.fetch({}));
        const tcs = tcsQuery && tcsQuery.data && tcsQuery.data.testcenters || [];
        this.testcenters = tcs.map(x => Object.assign(x, { display: `${x.testcenterId} ${x.name}` }));
        if (!tcs.find(x => x.testcenterId === this.testcenterId)) {
            this.testcenterId = tcs.length ? tcs[0].testcenterId : undefined;
        }
        if (this.testcenterId) {
            const q = await firstValueFrom(this.qSvc.fetch({
                id: this.testcenterId
            }));
            this.tan_allowance = q.data.testcenter.allowance;
            this.tan_used = q.data.testcenter.tansummary.total;
            this.tanGroups = q.data.testcenter.tanGroups.map(x => {
                return Object.assign(x, {
                    password: this.svc.getTanGroupPassword(x.id),
                    isDone: x.status === Types.TanGroupStatus.Done
                });
            });
            this.modules = q.data.testcenter.modules;
        } else {
            this.tanGroups = null;
            this.modules = null;
        }
    }


    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    setupFormItems() {
        this.newExamFormItems.splice(0, this.newExamFormItems.length);
        this.newExamFormItems.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'name',
            label: {
                text: this.translate.instant(_('preparation.dashboard.dialog.new.fields.name'))
            },
            validationRules: [{
                type: 'required'
            }]
        }));
        this.newExamFormItems.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'module',
            label: {
                text: this.translate.instant(_('preparation.dashboard.dialog.new.fields.module'))
            },
            validationRules: [{
                type: 'required'
            }],
            editorType: 'dxSelectBox',
            editorOptions: AS<DevExpress.ui.dxSelectBox.Properties>({
                dataSource: this.modules,
                displayExpr: 'shortName',
                valueExpr: 'id'
            })
        }));
        this.newExamFormItems.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'valid_to',
            label: {
                text: this.translate.instant(_('preparation.dashboard.dialog.new.fields.valid_to'))
            },
            validationRules: [{
                type: 'required'
            }],
            editorType: 'dxDateBox',
            editorOptions: AS<DevExpress.ui.dxDateBox.Properties>({
                min: this.minValidTo,
                max: this.maxValidTo
            })
        }));
        this.newExamFormItems.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'password',
            label: {
                text: this.translate.instant(_('preparation.dashboard.dialog.new.fields.password'))
            },
            validationRules: [],

        }));
        this.newExamFormItems.push(AS<DevExpress.ui.dxForm.SimpleItem>({
            dataField: 'tans',
            label: {
                text: this.translate.instant(_('preparation.dashboard.dialog.new.fields.tans'))
            },
            validationRules: [{
                type: 'required'
            }],
            editorType: 'dxNumberBox',
            editorOptions: AS<DevExpress.ui.dxNumberBox.Properties>({
                min: 1,
                max: this.maxTans
            }),

        }));

        addMetaDataFields(this.newExamFormItems, this.init && this.init.tanGroupMetaData);
    }

    async ngOnInit() {

        const data = await firstValueFrom(this.initGqlSvc.fetch({}, {
            fetchPolicy: 'cache-first'
        }));
        this.init = data.data;

        this.subscriptions.push(this.route.paramMap.subscribe({
            next: async x => {
                const testcenterId = x.get('id');
                console.log(`id: ${testcenterId}`);
                if (testcenterId) {
                    this.testcenterId = testcenterId;
                    await this.refresh();
                    console.log('setting up form items');
                    if (!this.newExamFormItems.length) {
                        this.setupFormItems();
                    }
                }
            }
        }));

        await this.refresh();

    }

}

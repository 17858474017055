import type DevExpress from 'devextreme/bundles/dx.all';
import { ToolbarPreparingEvent } from 'devextreme/ui/data_grid';
import * as dialog from 'devextreme/ui/dialog';

export type DxFormItem = DevExpress.ui.dxForm.SimpleItem |
    DevExpress.ui.dxForm.GroupItem |
    DevExpress.ui.dxForm.TabbedItem |
    DevExpress.ui.dxForm.EmptyItem |
    DevExpress.ui.dxForm.ButtonItem;

export function sleep(x: number) {
    return new Promise(resolve => setTimeout(resolve, x));
}
export function AWAIT<T>(what: Promise<T> & JQueryPromise<T>): Promise<T> {
    return <Promise<T>><any>what;
}
export function _(str: string) {
    return str;
}

export function assertNever(x: never): void {

}

interface IToolbarPreparing {
    toolbarOptions: {
        items: Array<{
            location: 'before' | 'after';
            template?: string;
            widget?: string;
            options: any
        }>
    };
}
export function button_click(cb: (e: DevExpress.ui.dxButton.ClickEvent) => void) {
    return cb;
}
export function toolbar_preparing(cb: (e: ToolbarPreparingEvent) => void) {
    return cb;
}
export function AS<X>(x: X) {
    return x;
}

export function button_visible<X>(cb: (val: X) => boolean) {
    const x = (o: {
        component?: DevExpress.ui.dxDataGrid,
        row?: DevExpress.ui.dxDataGrid.Row,
        column?: DevExpress.ui.dxDataGrid.Column,
    }) => {
        if (o.row) {
            const val: X = o.row.data;
            return cb(val);
        }
        return false;
    };
    return x;
}


export function dxConfirm(msg: string, title: string) {
    return AWAIT(dialog.confirm(msg, title));
}
export function dxAlert(msg: string, title: string) {
    return AWAIT(dialog.alert(msg, title));
}

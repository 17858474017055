import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AppInitService } from '../app-init.service';
import { ensureArray } from '../helper';
import { PreparationExamService } from '../preparation-exam.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: false
})
export class HeaderComponent implements OnInit {
  public instanceName?: string;

  constructor(
    readonly svc: PreparationExamService,
    private readonly init: AppInitService,
    readonly location: Location) { }

  @Input() itslogoRoute?: string[] | string;

  itslogoClick() {
    let arr = ensureArray(this.itslogoRoute);
    if (!arr.length) {
      return;
    }
    if (arr.length === 1 && arr[0] === ':back') {
      this.location.back();
      return;
    }
    this.svc.navigate(arr, {});
  }
  
  async ngOnInit() {
    const settings = await this.init.getSettings();
    this.instanceName = settings.instanceName;

  }

}

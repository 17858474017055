import { Component, Input, OnInit } from '@angular/core';
import { formatNumber } from 'devextreme/localization';

@Component({
    selector: 'app-percentagebar',
    templateUrl: './percentagebar.component.html',
    styleUrls: ['./percentagebar.component.css'],
    standalone: false
})
export class PercentagebarComponent implements OnInit {

  @Input() percentage: number | undefined;

  display() {
    if (typeof this.percentage !== 'number') {
      return '-';
    }
    return formatNumber(this.percentage, {
      type: 'fixedPoint',
      precision: 0
    });
  }

  constructor() {
  }

  ngOnInit() {
  }

}

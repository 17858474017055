import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Types from '../its-organizer-api.types.g';

import { TranslateService } from '@ngx-translate/core';

import { firstValueFrom, fromEvent, Observable, Subscription } from 'rxjs';
import { ExamOpenMode } from '../app-config.service';
import { dxConfirm, sleep, _ } from '../dx_helper';
import { checkITSClient } from '../itsclient';
import { PreparationExamService } from '../preparation-exam.service';
import * as Q from './data.query.g';
import * as LAUNCH from './launchCandidate.mutation.g';
import * as DEMO from './launchDemo.mutation.g';

type Candidate = Q.ICandidate_Overview_DataQuery['person']['candidates'][0] & {
    disabled: boolean, widthRatio: number; heightRatio: number
};
type Exam = Q.ICandidate_Overview_DataQuery['person']['exam'];


async function waitForClose(wnd: Window) {
    while (true) {
        if (wnd.closed) {
            return;
        }
        await sleep(1000);
    }
}

class Topic {
    constructor(public readonly title: string) {

    }
    public readonly candidates: Candidate[] = [];
}

@Component({
    selector: 'app-candidate-overview',
    templateUrl: './candidate-overview.component.html',
    styleUrls: ['./candidate-overview.component.css'],
    standalone: false
})
export class CandidateOverviewComponent implements OnInit, OnDestroy {
    public candidates?: Candidate[];
    public exam?: Exam;
    private certDisclaimer?: string;

    private wnd?: Window;
    public examRunningVisible = false;

    private focusObservable$?: Observable<Event>;
    private focusSubscription$?: Subscription;

    public checkClientOk = true;
    public minClientVersion = '';
    public installUrl = '';
    private readonly openMode: ExamOpenMode;
    public readonly topics: Topic[] = [];

    constructor(
        private readonly qSvc: Q.ICandidate_Overview_DataGQL,
        private readonly demoSvc: DEMO.ICandidate_Overview_LaunchDemoGQL,
        private readonly launchSvc: LAUNCH.ICandidate_Overview_LaunchCandidateGQL,
        private readonly svc: PreparationExamService,
        private readonly translate: TranslateService,
    ) {
        this.openMode = window.__CONFIG?.EXAM_OPEN_MODE || 'replace';

    }

    public examRunningMsg() {
        return this.translate.instant(_('candidate.overview.examPopupOpen'));
    }

    async clickTile(e: { itemData?: Candidate }) {
        if (!e.itemData) {
            return;
        }
        const candidate = e.itemData;
        await this.svc.blockUI({
            name: `launch ${candidate.module.shortName}`,
            action: () => this.launch(candidate),
        });
    }

    async refresh() {
        const d = await firstValueFrom(this.qSvc.fetch({
        }));
        const p = d.data.person;
        this.minClientVersion = p.minITSClient;
        this.exam = p.exam;
        this.candidates = p.candidates.map(x => ({
            ...x,
            disabled: !x.startable,
            heightRatio: 1,
            widthRatio: 1,
        }));
        this.certDisclaimer = d.data.config.certDisclaimer.value;
        const topics: Topic[] = [];
        for (const c of this.candidates) {
            const mgTitle = c.module && c.module.topic || this.translate.instant(_('default_topic'));
            let ent = topics.find(x => x.title === mgTitle);
            if (!ent) {
                ent = new Topic(mgTitle);
                topics.push(ent);
            }
            ent.candidates.push(c);
        }
        this.topics.splice(0, this.topics.length, ...topics);
    }

    async ngOnInit() {
        this.focusObservable$ = fromEvent(window, 'focus');
        this.focusSubscription$ = this.focusObservable$.subscribe(evt => {
            console.log('event: ', evt);
            if (this.wnd) {
                console.log(`Putting focus to popup window`);
                this.wnd.focus();
            }
        });

        await this.refresh();
        this.checkClientOk = checkITSClient(this.minClientVersion);
        if (this.checkClientOk) {
            await this.svc.showCertDisclaimer(this.certDisclaimer);
        }
    }


    async ngOnDestroy() {
        this.focusSubscription$?.unsubscribe();

    }

    getDemoCssClass(candidate: Candidate) {
        if (!candidate.module.hasDemo) {
            return { hidden: true };
        }
        if (candidate.demoStatus === Types.CandidateStatus.Completed) {
            return { finished: true };
        }
        return {};
    }
    getLaunchCssClass(candidate: Candidate) {
        if (candidate.module.hasDemo) {
            if (candidate.demoStatus !== Types.CandidateStatus.Completed) {
                return { disabled: true };
            }
        }
        if (!candidate.startable) {
            return { finished: true };
        }
        return {};
    }
    getLaunchDemoButtonCssClass(candidate: Candidate) {
        const play = {
            fas: true,
            'fa-play-circle': true,
        };
        const done = {
            fas: true,
            'fa-check-circle': true,
        };
        if (!candidate.module.hasDemo) {
            return done;
        }
        if (candidate.demoStatus === Types.CandidateStatus.Completed) {
            return done;
        }
        return play;
    }

    getLaunchButtonCssClass(candidate: Candidate) {
        const play = {
            fas: true,
            'fa-play-circle': true,
        };
        const done = {
            fas: true,
            'fa-check-circle': true,
        };
        if (!candidate.startable) {
            return done;
        }
        return play;
    }

    getTileCssClass(candidate: Candidate) {
        const retVal: Record<string, boolean> = {};
        retVal['Tile'] = true;
        retVal['Module'] = true;
        if (candidate.module.cssClass) {
            retVal['Module_' + candidate.module.cssClass] = true;
        }
        retVal['finished'] = candidate.status === Types.CandidateStatus.Completed;
        return retVal;
    }
    async linkDisabled(candidate: Candidate) {
        return this.svc.alert(_('candidate.overview.alert.disabled'), _('candidate.overview.alert.disabled_title'));
    }
    async logout() {
        await this.svc.logout();
    }

    private closeWnd() {
        if (this.wnd) {
            this.wnd.close();
            this.wnd = undefined;
        }
    }

    private prepareLaunch() {
        if (this.openMode === 'popup') {
            const wnd = window.open('', '', 'fullscreen=yes');
            if (!wnd) {
                throw new Error('Unable to open window');
            }

            this.wnd = wnd;
            this.wnd.moveTo(0, 0);
            this.wnd.resizeTo(screen.availWidth, screen.availHeight);
        }

    }

    private async launchImpl(url: URL) {

        switch (this.openMode) {
            case 'popup':
                await this.svc.blockUI({
                    action: async () => {
                        url.searchParams.set('onclose', 'close');
                        console.log(`Opening in window ${url}`);
                        if (this.wnd) {
                            this.wnd.location.href = url.toString();
                            this.examRunningVisible = true;
                            await waitForClose(this.wnd);
                        }
                        await this.refresh();
                    }
                });
                break;
            case 'replace':
                const thisUrl = window.location.href;
                console.log(`After exam is completed, user should be redirected to ${thisUrl}`);
                url.searchParams.set('onclose', 'redirect:' + thisUrl);
                await this.svc.navigateAway(url.href);
        }
    }
    private async launchCandidateImpl(candidate: Candidate, getUrl: () => Promise<{ url: URL, confirm?: { title: string, text: string } }>) {
        this.prepareLaunch();

        try {
            const p = await getUrl();
            if (p.confirm && p.confirm.text) {
                if (!await dxConfirm(p.confirm.text, p.confirm.title)) {
                    this.closeWnd();
                    return;
                }
            }
            await this.launchImpl(p.url);

        } catch (e) {
            console.dir(e);
            await this.refresh();
            this.closeWnd();
            await this.svc.alert(_('candidate.overview.error.launch'), _('candidate.overview.error.launch_title'));
        } finally {
            console.log(`closing pop up window (if any)`);
            this.examRunningVisible = false;
            this.closeWnd();
        }

    }

    async launch(candidate: Candidate) {
        if (candidate.module.hasDemo) {
            if (candidate.demoStatus !== Types.CandidateStatus.Completed) {
                await this.launchCandidateImpl(candidate, async () => {
                    const url = await this.svc.networkactivity(() => firstValueFrom(this.demoSvc.mutate({
                        id: candidate.id,
                        userAgentString: window.navigator.userAgent,
                    })));
                    if (!url.data) {
                        throw new Error('invalid network request');
                    }
                    const p = new URL(url.data.launchDemo);
                    return {
                        url: p,
                    };
                });
                return;
            }
        }
        if (!candidate.startable) {
            return this.linkDisabled(candidate);
        }
        await this.launchCandidateImpl(candidate, async () => {
            const url = await this.svc.networkactivity(() => firstValueFrom(this.launchSvc.mutate({
                id: candidate.id,
                userAgentString: window.navigator.userAgent,
            })));
            if (!url.data) {
                throw new Error('invalid network request');
            }
            const p = new URL(url.data.launchCandidate);
            return {
                url: p,
                confirm: {
                    text: candidate.module.note.value,
                    title: this.translate.instant(_('candidate.overview.confirm.launch_title'))
                }
            };
        });
    }
}

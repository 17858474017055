import * as Types from '../its-organizer-api.types.g';

import { DocumentNode } from 'graphql';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date custom scalar type */
  LocalDate: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type ICandidate = {
  __typename?: 'Candidate';
  attempt: Scalars['Int']['output'];
  closeTime?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['LocalDate']['output']>;
  demoStatus: CandidateStatus;
  exam: IExam;
  grade?: Maybe<Scalars['String']['output']>;
  grade_letter?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  itembankDemoId: Scalars['String']['output'];
  itembankId: Scalars['String']['output'];
  metadata: Array<IMetaDataField>;
  module: IModule;
  moduleGroup?: Maybe<IModuleGroup>;
  name: Scalars['String']['output'];
  objectives?: Maybe<Array<IObjective>>;
  passed?: Maybe<Scalars['Boolean']['output']>;
  person: IPerson;
  progress?: Maybe<Scalars['String']['output']>;
  reference: Scalars['String']['output'];
  score?: Maybe<IScore>;
  sections?: Maybe<Array<ISection>>;
  startTime?: Maybe<Scalars['String']['output']>;
  startable: Scalars['Boolean']['output'];
  status: CandidateStatus;
  timePassed?: Maybe<Scalars['String']['output']>;
};

export enum CandidateStatus {
  Completed = 'completed',
  Inprogress = 'inprogress',
  Open = 'open'
}

export type IConfig = {
  __typename?: 'Config';
  autoRefreshInterval: Scalars['Int']['output'];
  blockRefresh: Scalars['Int']['output'];
  certDisclaimer: ILangString;
  forceITSR3Client: ForceItsr3Client;
  hasCertification: Scalars['Boolean']['output'];
  hasPreparation: Scalars['Boolean']['output'];
  hideObjectiveId: Scalars['Boolean']['output'];
  instanceName: ILangString;
  interimReportEnabled: Scalars['Boolean']['output'];
  lang: Scalars['String']['output'];
  maxObjectiveLevel: Scalars['Int']['output'];
  minObjectiveLevel: Scalars['Int']['output'];
  newTanGroupTans: Scalars['Int']['output'];
  oidcProctorSignup: OidcProctorSignup;
  supportIFrameUrl?: Maybe<Scalars['String']['output']>;
  tanDisclaimer: ILangString;
  tanDisplayProgress: DisplayProgress;
  /** How many days in future the tan exam can be created (this is +1 to the date in the UI to handle timezone differences */
  tanMaxDays: Scalars['Int']['output'];
  testcenterWelcomeEmail: IEMailConfig;
};

export type ICsvOptions = {
  __typename?: 'CsvOptions';
  disabled: Scalars['Boolean']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  line: Scalars['String']['output'];
  quote: Scalars['String']['output'];
  separator: Scalars['String']['output'];
};

export type IDbQuery = {
  __typename?: 'DbQuery';
  cronExport: Scalars['Boolean']['output'];
  csvOptions: ICsvOptions;
  disabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  includeInExamZip: Scalars['Boolean']['output'];
  queryId: Scalars['ID']['output'];
  queryType: QueryTypeEnum;
  sql: Scalars['String']['output'];
  uiLabel: ILangString;
};

export enum DisplayProgress {
  Always = 'Always',
  CandidateComplete = 'CandidateComplete',
  Never = 'Never'
}

export type IEMailConfig = {
  __typename?: 'EMailConfig';
  body: ILangString;
  id: Scalars['ID']['output'];
  subject: ILangString;
};

export type IExam = {
  __typename?: 'Exam';
  candidates: Array<ICandidate>;
  certificate: Scalars['String']['output'];
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isCurrent: Scalars['Boolean']['output'];
  isStandalone: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  metadata: Array<IMetaDataField>;
  modules: Array<IModule>;
  name: Scalars['String']['output'];
  persons: Array<IPerson>;
  status: ExamStatus;
  testcenter: ITestcenter;
};

export enum ExamStatus {
  Done = 'done',
  Open = 'open'
}

export enum ForceItsr3Client {
  Always = 'Always',
  Never = 'Never',
  OnlyCandidate = 'OnlyCandidate'
}

export type IIStatus = {
  status: ServerStatus;
};

export type IItem = {
  __typename?: 'Item';
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  itemId: Scalars['String']['output'];
  score?: Maybe<IScore>;
};

export type ILangString = {
  __typename?: 'LangString';
  id: Scalars['ID']['output'];
  translations: Array<ILangStringValue>;
  value: Scalars['String']['output'];
  xnone: Scalars['String']['output'];
};

export type ILangStringValue = {
  __typename?: 'LangStringValue';
  id: Scalars['ID']['output'];
  isoCode: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type IMetaDataComboField = IMetaDataField & {
  __typename?: 'MetaDataComboField';
  id: Scalars['ID']['output'];
  key: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  options: Array<IMetaDataComboFieldOption>;
  required: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type IMetaDataComboFieldOption = {
  __typename?: 'MetaDataComboFieldOption';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type IMetaDataField = {
  id: Scalars['ID']['output'];
  key: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export type IMetaDataTextField = IMetaDataField & {
  __typename?: 'MetaDataTextField';
  id: Scalars['ID']['output'];
  key: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  regex: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type IMetaDataValueInput = {
  key: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type IModule = {
  __typename?: 'Module';
  canBeResetByCandidate: Scalars['Boolean']['output'];
  canBeResetByProctor: Scalars['Boolean']['output'];
  canBeResetBySysAdmin: Scalars['Boolean']['output'];
  cssClass: Scalars['String']['output'];
  disabled: Scalars['Boolean']['output'];
  hasDemo: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  includeDetailsPdf: Scalars['Boolean']['output'];
  longName?: Maybe<Scalars['String']['output']>;
  maxScore?: Maybe<Scalars['Float']['output']>;
  moduleId: Scalars['String']['output'];
  note: ILangString;
  reset: ModuleReset;
  shortName: Scalars['String']['output'];
  topic?: Maybe<Scalars['String']['output']>;
};

export type IModuleGroup = {
  __typename?: 'ModuleGroup';
  disabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  longName: ILangString;
  modules: Array<IModule>;
  shortName: ILangString;
};

export enum ModuleReset {
  ByCandidate = 'by_candidate',
  ByProctor = 'by_proctor',
  BySysadmin = 'by_sysadmin',
  Disabled = 'disabled'
}

export type IMutation = {
  __typename?: 'Mutation';
  closeTanGroup: Scalars['Boolean']['output'];
  deleteTanGroup: Scalars['Boolean']['output'];
  dummy?: Maybe<Scalars['String']['output']>;
  finishExam: Scalars['Boolean']['output'];
  impersonateCandidate: Scalars['String']['output'];
  impersonateProctor: Scalars['String']['output'];
  /** @deprecated Use JOB MANAGER to import sharepoints */
  importSharePoints: Scalars['String']['output'];
  insertExam: IExam;
  launchCandidate: Scalars['String']['output'];
  launchDemo: Scalars['String']['output'];
  launchTan: Scalars['String']['output'];
  launchTanGroupModuleUntracked: Scalars['String']['output'];
  login: Scalars['String']['output'];
  loginCandidate: Scalars['String']['output'];
  logout: Scalars['Boolean']['output'];
  newTanGroup: ITanGroup;
  newTanGroupToken: Scalars['String']['output'];
  newToken: Scalars['ID']['output'];
  newTokens: Array<Scalars['ID']['output']>;
  refreshTanGroup: Scalars['Boolean']['output'];
  reopenExam: IExam;
  resetAttempt: ICandidate;
  resetTanGroupPassword: Scalars['String']['output'];
  sendPasswordToTestcenter: ISendPasswordToTestcenterResult;
  /** @deprecated use xlsx upload instead */
  setName: Scalars['Boolean']['output'];
  /** @deprecated use xlsx upload instead */
  setNames: Scalars['Boolean']['output'];
  setPresent: IPerson;
  setTanGroupNames: Scalars['Boolean']['output'];
  syncExam: Scalars['Boolean']['output'];
  syncExams: Scalars['Boolean']['output'];
  updateConfig: Scalars['Boolean']['output'];
  updateExam: IExam;
  updateProctor: IProctor;
  updateTestcenter: ITestcenter;
  upsertAuth: Scalars['Boolean']['output'];
  upsertDbQuery: Scalars['Boolean']['output'];
  upsertModuleGroup: IModuleGroup;
  upsertProctoredExam: IExam;
  upsertSharePoint: ISharePoint;
  validateTan?: Maybe<ITanInfo>;
};


export type IMutationCloseTanGroupArgs = {
  id: Scalars['ID']['input'];
  password: Scalars['String']['input'];
};


export type IMutationDeleteTanGroupArgs = {
  id: Scalars['ID']['input'];
  password: Scalars['String']['input'];
};


export type IMutationDummyArgs = {
  in?: InputMaybe<Scalars['String']['input']>;
};


export type IMutationFinishExamArgs = {
  examId: Scalars['ID']['input'];
};


export type IMutationImpersonateCandidateArgs = {
  examId: Scalars['ID']['input'];
  reference: Scalars['String']['input'];
};


export type IMutationImpersonateProctorArgs = {
  proctorIdOrKey: Scalars['ID']['input'];
};


export type IMutationInsertExamArgs = {
  examDate: Scalars['String']['input'];
  metadata?: InputMaybe<Array<IMetaDataValueInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  testcenterId: Scalars['ID']['input'];
  xlsxFile: Scalars['Upload']['input'];
};


export type IMutationLaunchCandidateArgs = {
  candidate: Scalars['ID']['input'];
  userAgentString: Scalars['String']['input'];
};


export type IMutationLaunchDemoArgs = {
  candidate: Scalars['ID']['input'];
  userAgentString: Scalars['String']['input'];
};


export type IMutationLaunchTanArgs = {
  metadata?: InputMaybe<Array<IMetaDataValueInput>>;
  tan: Scalars['ID']['input'];
  userAgentString: Scalars['String']['input'];
};


export type IMutationLaunchTanGroupModuleUntrackedArgs = {
  id: Scalars['ID']['input'];
  userAgentString: Scalars['String']['input'];
};


export type IMutationLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type IMutationLoginCandidateArgs = {
  distinguisher: Scalars['String']['input'];
  examId: Scalars['ID']['input'];
};


export type IMutationNewTanGroupArgs = {
  maxTans: Scalars['Int']['input'];
  metadata?: InputMaybe<Array<IMetaDataValueInput>>;
  module: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  testcenter: Scalars['ID']['input'];
  validTo?: InputMaybe<Scalars['String']['input']>;
};


export type IMutationNewTanGroupTokenArgs = {
  id: Scalars['ID']['input'];
  password: Scalars['String']['input'];
  readonly: Scalars['Boolean']['input'];
};


export type IMutationNewTokensArgs = {
  count: Scalars['Int']['input'];
};


export type IMutationRefreshTanGroupArgs = {
  id: Scalars['ID']['input'];
  includeOpen?: InputMaybe<Scalars['Boolean']['input']>;
};


export type IMutationReopenExamArgs = {
  examId: Scalars['ID']['input'];
};


export type IMutationResetAttemptArgs = {
  candidate: Scalars['ID']['input'];
};


export type IMutationResetTanGroupPasswordArgs = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  newPassword: Scalars['String']['input'];
  tanGroupId: Scalars['ID']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};


export type IMutationSendPasswordToTestcenterArgs = {
  dryRun: Scalars['Boolean']['input'];
  testcenterId: Scalars['String']['input'];
};


export type IMutationSetNameArgs = {
  name: Scalars['String']['input'];
  tan: Scalars['ID']['input'];
};


export type IMutationSetNamesArgs = {
  names: Array<Scalars['String']['input']>;
  tans: Array<Scalars['ID']['input']>;
};


export type IMutationSetPresentArgs = {
  examId: Scalars['ID']['input'];
  present: Scalars['Boolean']['input'];
  reference: Scalars['String']['input'];
};


export type IMutationSetTanGroupNamesArgs = {
  id: Scalars['ID']['input'];
  names: Array<Scalars['ID']['input']>;
  password: Scalars['String']['input'];
  tans: Array<Scalars['ID']['input']>;
};


export type IMutationSyncExamArgs = {
  examId: Scalars['ID']['input'];
};


export type IMutationUpdateConfigArgs = {
  params: IUpdateConfig;
};


export type IMutationUpdateExamArgs = {
  examId: Scalars['ID']['input'];
  xlsxFile: Scalars['Upload']['input'];
};


export type IMutationUpdateProctorArgs = {
  createIfNotExists?: InputMaybe<Scalars['Boolean']['input']>;
  input: IUpdateProctorInput;
};


export type IMutationUpdateTestcenterArgs = {
  createIfNotExists?: InputMaybe<Scalars['Boolean']['input']>;
  input: IUpdateTestcenterInput;
};


export type IMutationUpsertAuthArgs = {
  input: IUpsertAuthInput;
};


export type IMutationUpsertDbQueryArgs = {
  params: IUpsertDbQuery;
};


export type IMutationUpsertModuleGroupArgs = {
  params: IUpsertModuleGroup;
};


export type IMutationUpsertProctoredExamArgs = {
  examDate: Scalars['String']['input'];
  examKey: Scalars['String']['input'];
  metadata?: InputMaybe<Array<IMetaDataValueInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  proctorId: Scalars['ID']['input'];
  testcenterId: Scalars['ID']['input'];
  xlsxFileBase64: Scalars['String']['input'];
};


export type IMutationUpsertSharePointArgs = {
  params: IUpsertSharePointInput;
};


export type IMutationValidateTanArgs = {
  tan: Scalars['ID']['input'];
};

export type IObjective = {
  __typename?: 'Objective';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  score: IScore;
};

export enum OidcProctorSignup {
  FirstnameLastnameMatch = 'FirstnameLastnameMatch',
  Never = 'Never'
}

export type IPerson = {
  __typename?: 'Person';
  allExamsCompleted: Scalars['Boolean']['output'];
  anyExamStarted: Scalars['Boolean']['output'];
  candidates: Array<ICandidate>;
  dateOfBirth?: Maybe<Scalars['LocalDate']['output']>;
  exam: IExam;
  id: Scalars['ID']['output'];
  loggedIn: Scalars['Boolean']['output'];
  minITSClient: Scalars['String']['output'];
  name: Scalars['String']['output'];
  present: Scalars['Boolean']['output'];
  reference: Scalars['String']['output'];
};

export type IProctor = {
  __typename?: 'Proctor';
  disabled: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  proctorId: Scalars['String']['output'];
};

export type IProctorFilterInput = {
  standalone?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IQuery = {
  __typename?: 'Query';
  candidate: ICandidate;
  config: IConfig;
  dbQueries: Array<IDbQuery>;
  dummy?: Maybe<Scalars['String']['output']>;
  exam: IExam;
  examMetaData: Array<IMetaDataField>;
  exams: Array<IExam>;
  examsByKey: Array<IExam>;
  info: Scalars['String']['output'];
  modulegroups: Array<IModuleGroup>;
  modules: Array<IModule>;
  person: IPerson;
  proctor: IProctor;
  proctors: Array<IProctor>;
  sharepoints: Array<ISharePoint>;
  status: IStatus;
  tanGroup: ITanGroup;
  tanGroupMetaData: Array<IMetaDataField>;
  testcenter: ITestcenter;
  testcenters: Array<ITestcenter>;
  whoAmI: IWhoAmI;
};


export type IQueryCandidateArgs = {
  candidate: Scalars['ID']['input'];
};


export type IQueryExamArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryExamsByKeyArgs = {
  ignoreAccessFailure?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreNotFoundFailure?: InputMaybe<Scalars['Boolean']['input']>;
  keys: Array<Scalars['ID']['input']>;
  status?: InputMaybe<ExamStatus>;
};


export type IQueryProctorArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryProctorsArgs = {
  filter?: InputMaybe<IProctorFilterInput>;
};


export type IQueryTanGroupArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryTestcenterArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryTestcentersArgs = {
  filter?: InputMaybe<ITestcenterFilterInput>;
};

export enum QueryTypeEnum {
  Exam = 'exam',
  System = 'system'
}

export type IScore = {
  __typename?: 'Score';
  gained: Scalars['Float']['output'];
  lost: Scalars['Float']['output'];
  mastery?: Maybe<Scalars['Float']['output']>;
  max: Scalars['Float']['output'];
  pending: Scalars['Float']['output'];
  percent: Scalars['Float']['output'];
};

export type ISection = {
  __typename?: 'Section';
  id: Scalars['ID']['output'];
  items: Array<IItem>;
  title: Scalars['String']['output'];
};

export type ISendPasswordToTestcenterResult = {
  __typename?: 'SendPasswordToTestcenterResult';
  email_cc: Array<Scalars['String']['output']>;
  email_to: Array<Scalars['String']['output']>;
};

export enum ServerStatus {
  Maintainance = 'maintainance',
  Ok = 'ok'
}

export type ISharePoint = {
  __typename?: 'SharePoint';
  direction: SharePointDirection;
  disabled: Scalars['Boolean']['output'];
  folder: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sharePointId: Scalars['String']['output'];
  siteUrl: Scalars['String']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export enum SharePointDirection {
  Export = 'export',
  Import = 'import'
}

export type IStatus = IIStatus & {
  __typename?: 'Status';
  status: ServerStatus;
};

export type ISubscription = {
  __typename?: 'Subscription';
  dummy?: Maybe<Scalars['String']['output']>;
};

export type ITan = {
  __typename?: 'Tan';
  grade?: Maybe<Scalars['String']['output']>;
  grade_letter?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  metadata: Array<IMetaDataField>;
  name?: Maybe<Scalars['String']['output']>;
  objectives?: Maybe<Array<IObjective>>;
  passed?: Maybe<Scalars['Boolean']['output']>;
  progress?: Maybe<Scalars['String']['output']>;
  score?: Maybe<IScore>;
  sections?: Maybe<Array<ISection>>;
  startTime?: Maybe<Scalars['String']['output']>;
  status: TanStatus;
  tan: Scalars['String']['output'];
};

export type ITanGroup = {
  __typename?: 'TanGroup';
  hasPassword: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  maxTans: Scalars['Int']['output'];
  metadata: Array<IMetaDataField>;
  module: IModule;
  name: Scalars['String']['output'];
  status: TanGroupStatus;
  tan: ITan;
  tans: Array<ITan>;
  tansummary: ITanGroupTanSummary;
  testcenter: ITestcenter;
  validTo: Scalars['String']['output'];
  validatePassword: Scalars['Boolean']['output'];
  visibleTo: Scalars['String']['output'];
};


export type ITanGroupTanArgs = {
  idOrKey: Scalars['ID']['input'];
  password: Scalars['String']['input'];
};


export type ITanGroupTansArgs = {
  password: Scalars['String']['input'];
};


export type ITanGroupValidatePasswordArgs = {
  password: Scalars['String']['input'];
};

export enum TanGroupStatus {
  Done = 'done',
  Open = 'open'
}

export type ITanGroupTanSummary = {
  __typename?: 'TanGroupTanSummary';
  complete: Scalars['Int']['output'];
  inprogress: Scalars['Int']['output'];
  open: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type ITanInfo = {
  __typename?: 'TanInfo';
  id: Scalars['ID']['output'];
  metaData: Array<IMetaDataField>;
  module: IModule;
};

export enum TanStatus {
  Completed = 'completed',
  Inprogress = 'inprogress',
  Open = 'open'
}

export type ITestcenter = {
  __typename?: 'Testcenter';
  allowance: Scalars['Int']['output'];
  disabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  modules: Array<IModule>;
  name: Scalars['String']['output'];
  standalone: Scalars['Boolean']['output'];
  tanGroups: Array<ITanGroup>;
  tansummary: ITanGroupTanSummary;
  testcenterId: Scalars['String']['output'];
  username?: Maybe<Scalars['String']['output']>;
};


export type ITestcenterTanGroupsArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ITestcenterFilterInput = {
  standalone?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IUpdateConfig = {
  autoRefreshInterval?: InputMaybe<Scalars['Int']['input']>;
  blockRefresh?: InputMaybe<Scalars['Int']['input']>;
  certDisclaimer?: InputMaybe<IUpsertLangString>;
  displayObjectiveId?: InputMaybe<Scalars['Boolean']['input']>;
  forceITSR3Client?: InputMaybe<ForceItsr3Client>;
  hasCertification?: InputMaybe<Scalars['Boolean']['input']>;
  hasPreparation?: InputMaybe<Scalars['Boolean']['input']>;
  instanceName?: InputMaybe<IUpsertLangString>;
  interimReportEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  maxObjectiveLevel?: InputMaybe<Scalars['Int']['input']>;
  minObjectiveLevel?: InputMaybe<Scalars['Int']['input']>;
  oidcProctorSignup?: InputMaybe<OidcProctorSignup>;
  supportIFrameUrl?: InputMaybe<Scalars['String']['input']>;
  tanDisclaimer?: InputMaybe<IUpsertLangString>;
  tanDisplayProgress?: InputMaybe<DisplayProgress>;
  tanMaxDays?: InputMaybe<Scalars['Int']['input']>;
  testcenterWelcomeEmail?: InputMaybe<IUpsertEMailConfig>;
};

export type IUpdateProctorInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  idOrKey: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  proctorId?: InputMaybe<Scalars['ID']['input']>;
};

export type IUpdateTestcenterInput = {
  allowance?: InputMaybe<Scalars['Int']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  exportPassword?: InputMaybe<Scalars['String']['input']>;
  idOrKey: Scalars['ID']['input'];
  limitModules?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  standalone?: InputMaybe<Scalars['Boolean']['input']>;
  testcenterId?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type IUpsertAuthInput = {
  authId: Scalars['ID']['input'];
  webhook?: InputMaybe<Scalars['String']['input']>;
};

export type IUpsertCsvOptions = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  line?: InputMaybe<Scalars['String']['input']>;
  quote?: InputMaybe<Scalars['String']['input']>;
  separator?: InputMaybe<Scalars['String']['input']>;
};

export type IUpsertDbQuery = {
  cronExport?: InputMaybe<Scalars['Boolean']['input']>;
  csvOptions?: InputMaybe<IUpsertCsvOptions>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  includeInExamZip?: InputMaybe<Scalars['Boolean']['input']>;
  queryId?: InputMaybe<Scalars['ID']['input']>;
  queryType?: InputMaybe<QueryTypeEnum>;
  sql?: InputMaybe<Scalars['String']['input']>;
  uiLabel?: InputMaybe<IUpsertLangString>;
};

export type IUpsertEMailConfig = {
  body?: InputMaybe<IUpsertLangString>;
  subject?: InputMaybe<IUpsertLangString>;
};

export type IUpsertLangString = {
  addValues?: InputMaybe<Array<IUpsertLangStringValue>>;
  deleteValues?: InputMaybe<Array<Scalars['String']['input']>>;
  setValues?: InputMaybe<Array<IUpsertLangStringValue>>;
  setXnone?: InputMaybe<Scalars['String']['input']>;
};

export type IUpsertLangStringValue = {
  isoCode: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type IUpsertModuleGroup = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['ID']['input']>;
  longName?: InputMaybe<IUpsertLangString>;
  shortName?: InputMaybe<IUpsertLangString>;
};

export type IUpsertSharePointInput = {
  direction?: InputMaybe<SharePointDirection>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  sharePointId?: InputMaybe<Scalars['String']['input']>;
  siteUrl?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type IWhoAmI = {
  __typename?: 'WhoAmI';
  isAnonymous: Scalars['Boolean']['output'];
  isCandidate: Scalars['Boolean']['output'];
  isProctor: Scalars['Boolean']['output'];
};

export type ICheck_ProctorQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ICheck_ProctorQuery = { __typename?: 'Query', whoAmI: { __typename?: 'WhoAmI', isProctor: boolean } };

export const Check_ProctorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"check_proctor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"whoAmI"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isProctor"}}]}}]}}]} as unknown as DocumentNode;

  @Injectable({
    providedIn: 'root'
  })
  export class ICheck_ProctorGQL extends Apollo.Query<ICheck_ProctorQuery, ICheck_ProctorQueryVariables> {
    override document = Check_ProctorDocument;
    override client = 'itsorganizerapi';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
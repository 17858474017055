import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { loadMessages } from 'devextreme/localization';
import deMessages from 'devextreme/localization/messages/de.json';
import { BUILD_INFO } from '../current.build';
import { MyOidcAuthService } from './my-oidc-auth.service';


loadMessages(deMessages);

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [],
    standalone: false
})
export class AppComponent implements OnInit {

  public readonly version = BUILD_INFO.semVer;

  constructor(
    readonly translate: TranslateService,
    private readonly authService: MyOidcAuthService) {
    this.authService.runInitialLoginSequence();
  }


  async ngOnInit() {
  }
}

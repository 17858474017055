import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { ApolloModule, APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
  DxAccordionModule, DxAutocompleteModule,
  DxBarGaugeModule, DxBoxModule, DxBulletModule, DxButtonModule, DxCalendarModule,
  DxChartModule, DxCheckBoxModule, DxColorBoxModule, DxDataGridModule, DxDateBoxModule,
  DxDropDownBoxModule, DxFileUploaderModule, DxFilterBuilderModule, DxFormModule, DxGalleryModule,
  DxListModule, DxLoadIndicatorModule,
  DxLoadPanelModule, DxLookupModule, DxMenuModule, DxNumberBoxModule, DxPivotGridModule,
  DxPopupModule, DxProgressBarModule, DxRadioGroupModule, DxRangeSliderModule, DxSchedulerModule, DxScrollViewModule, DxSelectBoxModule, DxSliderModule, DxSwitchModule, DxTabPanelModule,
  DxTabsModule, DxTagBoxModule, DxTemplateModule, DxTextAreaModule, DxTextBoxModule, DxTileViewModule,
  DxToolbarModule, DxTreeListModule,
  DxTreeViewModule
} from 'devextreme-angular';
import { Observable, of } from 'rxjs';
import { AppRoutingModule } from './/app-routing.module';
import { AppConfigService } from './app-config.service';
import { AppInitModule } from './app-init.module';
import { AppComponent } from './app.component';
import { CandidateOverviewComponent } from './candidate-overview/candidate-overview.component';
import { CandidateReportComponent } from './candidate-report/candidate-report.component';
import { CertDashboardComponent } from './cert-dashboard/cert-dashboard.component';
import { CertExamComponent, SafePipe } from './cert-exam/cert-exam.component';
import { ExamReportComponent } from './exam-report/exam-report.component';
import * as GQL from './graphql';
import { HeaderComponent } from './header/header.component';
import { LANG } from './i18n/lang.g';
import { ItsclientRequiredComponent } from './itsclient-required/itsclient-required.component';
import { LoginOidcProctorComponent } from './login-oidc-proctor/login-oidc-proctor.component';
import { LoginComponent } from './login/login.component';
import { MyOidcAuthService } from './my-oidc-auth.service';
import { NeedAuthGuard } from './need-auth-guard';
import { NeedITSR3Guard } from './need-itsr3-guard';
import { NewCertExamComponent } from './new-cert-exam/new-cert-exam.component';
import { MyOidcForcedLoginGuard } from './oidc-force-login-guard.service';
import { PercentagebarComponent } from './percentagebar/percentagebar.component';
import { PreparationDashboardComponent } from './preparation-dashboard/preparation-dashboard.component';
import { LoginTokenService } from './preparation-exam.service';
import { PreparationITSR3Component } from './preparation-itsr3/preparation-itsr3.component';
import { PreparationTangroupComponent } from './preparation-tangroup/preparation-tangroup.component';
import { RefreshButtonComponent } from './refresh-button/refresh-button.component';
import { ScoreComponent } from './score/score.component';
import { TanReportComponent } from './tan-report/tan-report.component';
import { TangroupReportComponent } from './tangroup-report/tangroup-report.component';
import { TanoverviewComponent } from './tanoverview/tanoverview.component';





const COMPILED: { [lang: string]: { [key: string]: string } } = {};

// We need a factory since localStorage is not available at AOT build time
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function oAuthStorageFactory(): OAuthStorage {
  return localStorage;
}

export function oAuthConfigModuleFactory(config: AppConfigService): OAuthModuleConfig {
  const idmUrl = config.getIdmIssuer() || '';
  if (!idmUrl) {
    console.error('invalid config - IDM_ISSUER not set');
  }
  return {
    resourceServer: {
      allowedUrls: [idmUrl],
      sendAccessToken: true,
    },
  };
}
export function oAuthConfigFactory(config: AppConfigService): AuthConfig | undefined {
  const idmUrl = config.getIdmIssuer();
  if (!idmUrl) {
    console.log('invalid config - IDM_ISSUER not set');
    return undefined;
  }
  const clientId = config.getIdmClientId();
  if (!clientId) {
    console.log('invalid config - IDM_CLIENTID not set');
    return undefined;
  }
  const r: AuthConfig = {
    issuer: idmUrl,

    redirectUri: new URL('login-oidc-proctor', window.location.origin).toString(),
    postLogoutRedirectUri: window.location.origin,

    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
    clientId: clientId,

    // Just needed if your auth server demands a secret. In general, this
    // is a sign that the auth server is not configured with SPAs in mind
    // and it might not enforce further best practices vital for security
    // such applications.
    // dummyClientSecret: 'secret',

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: 'openid profile email offline_access',

    showDebugInformation: true,
    useSilentRefresh: false,

    //https://github.com/manfredsteyer/angular-oauth2-oidc/issues/977
    disableAtHashCheck: true,
  };
  console.log(JSON.stringify(r));
  return r;
}
export class StaticTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    if (!(lang in COMPILED)) {
      const parts = lang.split('-');
      const toJoin = [];
      for (let i = 0; i < parts.length; ++i) {
        const langCode = parts.slice(0, i + 1).join('-');
        if (langCode in LANG) {
          toJoin.push(LANG[langCode]);
        }
      }
      if (!toJoin.length) {
        console.log(`we don\'t have a language for ${lang}`);
      }
      COMPILED[lang] = Object.assign({}, ...toJoin);
    }
    return of(COMPILED[lang]);
  }
}
/*
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
*/
export function createTranslateLoader() {
  return new StaticTranslateLoader();
}

@NgModule({ declarations: [
        SafePipe,
        AppComponent,
        LoginComponent,
        PreparationITSR3Component,
        PreparationDashboardComponent,
        HeaderComponent,
        PreparationTangroupComponent,
        ScoreComponent,
        TanoverviewComponent,
        TangroupReportComponent,
        TanReportComponent,
        CertDashboardComponent,
        CertExamComponent,
        CandidateOverviewComponent,
        PercentagebarComponent,
        CandidateReportComponent,
        ExamReportComponent,
        RefreshButtonComponent,
        NewCertExamComponent,
        LoginOidcProctorComponent,
        ItsclientRequiredComponent,
    ],
    exports: [
        TranslateModule,
    ],
    bootstrap: [AppComponent], imports: [ApolloModule,
        AppInitModule,
        BrowserModule,
        DxListModule,
        DxChartModule,
        DxBarGaugeModule,
        DxFilterBuilderModule,
        DxDataGridModule,
        DxBoxModule,
        DxFormModule,
        DxTileViewModule,
        DxSchedulerModule,
        DxDateBoxModule,
        DxBulletModule,
        DxSwitchModule,
        DxTextBoxModule,
        DxProgressBarModule,
        DxRangeSliderModule,
        DxSelectBoxModule,
        DxPopupModule,
        DxAutocompleteModule,
        DxTagBoxModule,
        DxRadioGroupModule,
        DxColorBoxModule,
        DxCalendarModule,
        DxSliderModule,
        DxFileUploaderModule,
        DxDropDownBoxModule,
        DxNumberBoxModule,
        DxCheckBoxModule,
        DxTextAreaModule,
        DxLookupModule,
        DxLoadPanelModule,
        DxLoadIndicatorModule,
        DxButtonModule,
        DxAccordionModule,
        DxGalleryModule,
        DxTreeListModule,
        DxPivotGridModule,
        DxToolbarModule,
        DxMenuModule,
        DxTabsModule,
        DxTreeViewModule,
        DxTemplateModule,
        DxTabPanelModule,
        DxScrollViewModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                // deps: [HttpClient],
            }
        }),
        OAuthModule.forRoot()], providers: [
        AppConfigService,
        NeedAuthGuard,
        MyOidcAuthService,
        MyOidcForcedLoginGuard,
        NeedITSR3Guard,
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: GQL.createApollo,
            deps: [HttpLink, LoginTokenService, AppConfigService],
        },
        {
            provide: OAuthModuleConfig,
            useFactory: oAuthConfigFactory,
            deps: [AppConfigService]
        },
        {
            provide: AuthConfig,
            useFactory: oAuthConfigFactory,
            deps: [AppConfigService]
        },
        {
            provide: OAuthStorage,
            useFactory: oAuthStorageFactory,
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}



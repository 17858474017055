export const BUILD_INFO = {
  "releaseType": "FINAL",
  "ITS_ORGANIZER_UI": "registry.gitlab.com/bitmedia/its/its-organizer/its-organizer-ui:v3.4.0-next02--2025-01-30--09-24-28",
  "CI_COMMIT_REF_SLUG": "v3-4-0-next02",
  "buildDate": "2025-01-30T09:24:28.0000000+00:00",
  "CI_COMMIT_SHA": "df17e28afb47aa9ccfb8acc7f1502ff6b304771e",
  "CI_COMMIT_TAG": "v3.4.0-next02",
  "CI_COMMIT_REF_NAME": "v3.4.0-next02",
  "grow": 1,
  "ITS_ORGANIZER_UI_IMAGE": "registry.gitlab.com/bitmedia/its/its-organizer/its-organizer-ui",
  "DOCKER_TAG": "v3.4.0-next02",
  "serviceName": "its-organizer-ui",
  "ITS_ORGANIZER_UI_VERSION": "v3.4.0-next02--2025-01-30--09-24-28",
  "DEFAULT_CI_REGISTRY_IMAGE": "registry.gitlab.com/bitmedia/its/its-organizer/its-organizer-ui",
  "TARGET_PLATFORMS": "linux/amd64",
  "semVerMajor": 3,
  "DOCKER_TAG_ALTERNATE": "v3.4.0-next02--2025-01-30--09-24-28",
  "semVer": "3.4.0-next02"
} as const;

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CandidateOverviewComponent } from './candidate-overview/candidate-overview.component';
import { CandidateReportComponent } from './candidate-report/candidate-report.component';
import { CertDashboardComponent } from './cert-dashboard/cert-dashboard.component';
import { CertExamComponent } from './cert-exam/cert-exam.component';
import { ExamReportComponent } from './exam-report/exam-report.component';
import { LoginOidcProctorComponent } from './login-oidc-proctor/login-oidc-proctor.component';
import { LoginComponent } from './login/login.component';
import { NeedAuthGuard } from './need-auth-guard';
import { NeedITSR3Guard } from './need-itsr3-guard';
import { MyOidcForcedLoginGuard } from './oidc-force-login-guard.service';
import { PreparationDashboardComponent } from './preparation-dashboard/preparation-dashboard.component';
import { PreparationITSR3Component } from './preparation-itsr3/preparation-itsr3.component';
import { PreparationTangroupComponent } from './preparation-tangroup/preparation-tangroup.component';
import { TanReportComponent } from './tan-report/tan-report.component';
import { TangroupReportComponent } from './tangroup-report/tangroup-report.component';
import { TanoverviewComponent } from './tanoverview/tanoverview.component';


const routes: Routes = [
  {
    path: 'preparation-itsr3',
    component: PreparationITSR3Component,
  },
  {
    path: 'candidate-overview',
    component: CandidateOverviewComponent,
    canActivate: [NeedITSR3Guard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NeedITSR3Guard],
  },
  {
    path: 'login-oidc-proctor',
    //redirectTo: 'cert-dashboard',
    component: LoginOidcProctorComponent,
    canActivate: [MyOidcForcedLoginGuard],
  },
  {
    path: 'preparation-tangroup/:id',
    component: PreparationTangroupComponent,
    canActivate: [NeedAuthGuard]
  },
  {
    path: 'preparation-tangroup/:id/overview',
    component: TanoverviewComponent,
    canActivate: [NeedAuthGuard],
  },
  {
    path: 'candidate/:id/report',
    component: CandidateReportComponent,
    canActivate: [NeedAuthGuard],
  },
  {
    path: 'exam/:id/report',
    component: ExamReportComponent,
    canActivate: [NeedAuthGuard],
  },
  {
    path: 'preparation-tangroup/:id/report',
    component: TangroupReportComponent,
    canActivate: [NeedAuthGuard],
  },
  {
    path: 'preparation-tangroup/:id/tan/:tanid/report',
    component: TanReportComponent,
    canActivate: [NeedAuthGuard],
  },
  {
    path: 'preparation-dashboard',
    component: PreparationDashboardComponent,
    canActivate: [NeedAuthGuard]
  },
  {
    path: 'preparation-dashboard/:id',
    component: PreparationDashboardComponent,
    canActivate: [NeedAuthGuard]
  },
  {
    path: 'cert-dashboard',
    component: CertDashboardComponent,
    canActivate: [NeedAuthGuard]
  },
  {
    path: 'cert-exam/:id',
    component: CertExamComponent,
    canActivate: [NeedAuthGuard]
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

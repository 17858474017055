import { Component, Input, OnInit } from '@angular/core';
import { getITSClientVersion } from '../itsclient';

@Component({
    selector: 'app-itsclient-required',
    templateUrl: './itsclient-required.component.html',
    styleUrls: ['./itsclient-required.component.css'],
    standalone: false
})
export class ItsclientRequiredComponent implements OnInit {

  @Input() public minClientVersion = '';
  public installUrl = '';

  public info: {
    minVersion: string;
    curVersion: string;
  } | undefined = undefined;

  constructor() { }

  ngOnInit() {
    this.installUrl = (window.__CONFIG && window.__CONFIG.ITSCLIENT_INSTALL_URL) || '';
    this.info = {
      minVersion: this.minClientVersion,
      curVersion: getITSClientVersion() || '',
    };
  }

  installITSClient() {
    if (!this.installUrl) {
      return;
    }
    console.log(`Open ${this.installUrl} in new window`);
    window.open(this.installUrl, '_blank');
  }

  openInClient() {
    const newLocation = 'itsr3:' + location.href;
    console.log(`navigate to ${newLocation}`);
    location.href = newLocation;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { AppConfigService } from '../app-config.service';
import * as Types from '../its-organizer-api.types.g';
import { LoginTokenService, PreparationExamService } from '../preparation-exam.service';
import * as Q from './data.query.g';
import * as SYNC from './sync.mutation.g';

type Testcenter = Q.ICertdashboard_DataQuery['exams'][0]['testcenter'];

type Exam = Q.ICertdashboard_DataQuery['exams'][0] & {
  moduleDisplay: string;
  testcenterDisplay: string;
};

@Component({
    selector: 'app-cert-dashboard',
    templateUrl: './cert-dashboard.component.html',
    styleUrls: ['./cert-dashboard.component.css'],
    standalone: false
})
export class CertDashboardComponent implements OnInit {

  public isCertificateVisible = false;
  public isNameVisible = false;
  public currentExams: Exam[] | null = null;
  public recentExams: Exam[] | null = null;
  public isNewExamVisible = false;
  public showNewExam = false;
  public standaloneTC: Testcenter[] | undefined;
  public showFilterCurrent = true;
  constructor(
    private readonly tokenSvc: LoginTokenService,
    private readonly svc: PreparationExamService,
    private readonly qSvc: Q.ICertdashboard_DataGQL,
    private readonly syncSvc: SYNC.ICert_Dashboard_SyncGQL,
    private readonly config: AppConfigService,
    private readonly router: Router,
  ) { }

  async newExamImported(exam: { id: string, key: string }) {
    this.isNewExamVisible = false;
    await this.svc.navigate(['cert-exam', exam.id], {});
  }
  hasFile(exam: Exam) {
    return exam.status = Types.ExamStatus.Done;
  }

  async downloadFile(exam: Exam) {
    const examId = encodeURIComponent(exam.id);
    const access_token = this.tokenSvc.token && encodeURIComponent(this.tokenSvc.token);
    const url = this.config.getApiUrl() + `exam/${examId}/reports.zip?access_token=${access_token}`;
    console.log(url);
    window.open(url);
  }
  async newExam() {
    this.showNewExam = true;
  }
  async logout() {
    await this.svc.logout();
  }
  async goto(exam: Exam) {
    await this.svc.navigate(['cert-exam', exam.id], {});
  }
  async refresh() {
    await firstValueFrom(this.syncSvc.mutate({}));
    const r = await firstValueFrom(this.qSvc.fetch({
    }));
    const exams = r.data.exams.map(x => Object.assign(x, {
      moduleDisplay: x.modules.map(y => y.shortName).join(', '),
      testcenterDisplay: `${x.testcenter.testcenterId} ${x.testcenter.name}`.trim(),
    }));
    this.currentExams = exams.filter(x => x.isCurrent);
    this.recentExams = exams.filter(x => !x.isCurrent);
    this.standaloneTC = r.data.testcenters || [];
    this.isNewExamVisible = !!this.standaloneTC.length;
    this.isNameVisible = exams.some(x => !!x.name);
    this.isCertificateVisible = exams.some(x => !!x.certificate);
    this.showFilterCurrent = this.currentExams.length > 10;
    if (this.config.getExamNameMode() === 'required') {
      this.isNameVisible = true;
    }
  }
  async ngOnInit() {
    await this.refresh();
  }

}
